<template>
  <div class="body">
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%" @sort-change="sortChange"
      v-loading="loading">
      <!-- 操作 -->
      <el-table-column label="操作" width="70" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="CheckRow(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot-scope="scope">
            <!-- 上市状态 -->
            <span v-if="th.prop === 'is_on_stock'">{{scope.row[th.prop]?'已上市':'未上市'}}</span>
            <!-- 认证状态 -->
            <p v-else-if="th.prop === 'status-dot'" class="ident-status">
              <span :class="
                      scope.row[th.prop] === 1 ? 'green' : 'yellow'
                    "></span>
              {{ scope.row[th.prop]?'已认证':'未认证' }}
            </p>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import { CompanyList } from "../../api/member/member-detail";
import Pagination from "@/base/components/Default/Pagination";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      thead: [
        { label: "ID", prop: "id", minWidth: 50 },
        { label: "企业名称", prop: "name", minWidth: 150 },
        { label: "企业地址", prop: "address", minWidth: 150 },
        { label: "企业法人", prop: "oper_name", minWidth: 90 },
        { label: "上市公司", prop: "is_on_stock", minWidth: 90 },
        { label: "认证状态", prop: "ident_status", minWidth: 80 },
        { label: "管理员", prop: "manage_nickname", minWidth: 70 },
        { label: "在该企业职务", prop: "position", minWidth: 150 },
        {
          label: "成立时间",
          prop: "start_date",
          minWidth: 200,
          sortable: true,
        },
      ],
      filterForm: {
        id: this.data ? this.$deCode(this.data).id : this.$route.params.user_id,
        page_size: 15,
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取我的企业列表
    getCompanyList(pageData) {
      console.log(this.data);
      this.loading = true;
      CompanyList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击查看
    CheckRow(row) {
      this.$router.push({
        name: this.$route.params.user_id ? "UnitCompanyDetail" : "MyCompanyDetail",
        params: { id: row.id, type: "edit" },
      });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getCompanyList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getCompanyList(pageData);
    },
  },
  created() {
    this.getCompanyList(this.filterForm);
  },
  components: {
    Pagination,
  },
};
</script>
